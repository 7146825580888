import React, { useState } from "react"
import { graphql } from "gatsby"
import chunk from "lodash.chunk"
import Modal from "react-modal"
import { Parallax } from "react-parallax"

import useWindowDimensions from "../hooks/useWindowDimensions"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Section, Container } from "../components/Containers"
import Text from "../components/Text"
import { ImageText } from "../components/ImageText"
import Image from "../components/Image"
import Form from "../components/Form"
import { Button } from "../components/Buttons"

const PartnersPage = ({ pageContext, data }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const { metaTitle, metaDescription, socialImage, schemaPage } = pageContext
  const { imageText, communications, integrations } =
    data.allUniquePagesJson.nodes[0]

  const { width } = useWindowDimensions()

  //split array into 2 arrays
  const [left, right] = chunk(communications.bubbles, 2)

  return (
    <Layout className="tech-integrations-page">
      <SEO
        title={metaTitle}
        description={metaDescription}
        socialImage={socialImage}
        schemaPage={schemaPage}
      />

      <Section>
        <Container>
          <ImageText
            text={imageText.text}
            customButton={
              <button
                className="standard-button regular"
                onClick={() => setModalIsOpen(true)}>
                Partner with us
              </button>
            }
            image={imageText.image}
          />
        </Container>
      </Section>

      <Section>
        <Container>
          <Text
            as="h2"
            className="has-text-centered"
            text={communications.heading}
          />
          <div className="tech-integrations__messaging-container">
            <Parallax
              style={{ overflow: "visible" }}
              renderLayer={percentage => (
                <div
                  className="text-bubbles__left"
                  style={{
                    marginTop: width > 767 ? `-${percentage * 200 + 100}px` : 0,
                    transform:
                      width && width < 767
                        ? `translateX(
                     -${percentage * 100 + "px"})`
                        : "none"
                  }}>
                  {left.map(bubble => (
                    <p className="text-bubbles__bubble">{bubble}</p>
                  ))}
                </div>
              )}></Parallax>

            <Image publicId={communications.image} />

            <Parallax
              style={{ overflow: "visible" }}
              renderLayer={percentage => (
                <div
                  className="text-bubbles__right"
                  style={{
                    marginTop:
                      width && width > 767 ? `-${percentage * 200 + 100}px` : 0,
                    transform:
                      width && width < 767
                        ? `translateX(
                         ${percentage * 100 + "px"})`
                        : "none"
                  }}>
                  {right.map(bubble => (
                    <p className="text-bubbles__bubble">{bubble}</p>
                  ))}
                </div>
              )}></Parallax>
          </div>
        </Container>
        <Container maxWidth={630}>
          <Text text={communications.blurb} />
          <button
            className="standard-button regular mx-auto"
            onClick={() => setModalIsOpen(true)}>
            {communications.button.buttonText}
          </button>
        </Container>
      </Section>

      <Section>
        <Container maxWidth={630}>
          <Text
            as="h2"
            className="has-text-centered"
            text={integrations.heading}
          />
          <Text text={communications.blurb} />
        </Container>
        <Container>
          <div className="tech-integrations__logos-list">
            {integrations.companies.map(img => (
              <div key={img} className="tech-integrations__logos-list-image">
                <Image publicId={img} />
              </div>
            ))}
          </div>
        </Container>
      </Section>

      <Modal
        className="partners__modal"
        style={{
          overlay: {
            backgroundColor: "#000000d9",
            zIndex: 100
          }
        }}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Example Modal">
        <div
          onClick={() => setModalIsOpen(false)}
          className="partners__modal-close">
          ✕
        </div>
        <Form src="https://secureform.seamlessdocs.com/f/af13518651df508efdf715b6453ca725?embedded=true" />
      </Modal>
    </Layout>
  )
}

export const partnersPageQuery = graphql`
  query partnersQuery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        imageText {
          text
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          image
        }
        communications {
          heading
          blurb
          image
          button {
            href
            buttonText
            destination
            appearance
          }
          bubbles
        }
        integrations {
          heading
          blurb
          companies
        }
      }
    }
  }
`

export default PartnersPage
